import React from 'react'
const github_username = 'plumsydev'
const shown_name = 'user'
const discord_usertag = 'plumsy.dev'
const discord_userid = '1186428255922835616'
const email = 'hello@plumsy.dev'

const fetchGithubRepos = async (username: string) => {
	try {
		const response = await fetch(`https://api.github.com/users/${username}/repos`);
		const data = await response.json();
		if (Array.isArray(data)) {
			return data
				.filter((repo: any) => repo.name !== 'plumsydev') // Filter out the 'plumsydev' repo
				.map((repo: any) => `${repo.full_name}`);
		} else {
			console.error('Unexpected response format:', data);
			return [];
		}
	} catch (error) {
		console.error('Error fetching GitHub repos:', error);
		return [];
	}
}

const specificRepos = [
	'Antasia-Network-FR/minecraft.antasia.fr',
	'Antasia-Network-FR/antasia.fr',
	'Antasia-Network-FR/antasialauncher',
	'Antasia-Network-FR/antasia.cloud',
	'Antasia-Network-FR/target.tgbhy.fr'
];

let projects: string[] = [];

fetchGithubRepos(github_username).then(repos => {
	projects = [...specificRepos, ...repos];
	console.log('Fetched projects:', projects); // Log the projects array
	console.log('© plumsy.shell | 2024 | made with ❤ by plumsy.dev')
});

const links = [
	{
		name: 'GitHub',
		icon: 'fab fa-fw fa-github',
		link: 'https://github.com/plumsydev',
		description: 'My profile on GitHub',
	},
	{
		name: 'Website',
		icon: 'fas fa-fw fa-coffee',
		link: 'https://plumsy.dev/',
		description: 'Personal website (you\'re actually on it!)',
	},
	{
		name: 'Discord',
		link: `https://discord.com/users/${discord_userid}`,
		icon: 'fab fa-fw fa-discord',
		description: `${discord_usertag} | Add me as friend!`,
	},
	{
		name: 'E-mail',
		icon: 'fas fa-fw fa-at',
		link: `mailto:${email}`,
		description: 'Let\'s get in touch!',
	},
]

const info = (
	<>
		<p>Hi! Just some random French guy that likes tech :)</p>
		<p>
			I've got intermediate experience with{' '}
			<span className="highlighted">JavaScript</span>,{' '}
			<span className="highlighted">MySQL</span> and{' '}
			<span className="highlighted">Python.</span>
		</p>
		<p>
			I've been doing{' '}
			<span className="highlighted">front-end web designing</span> as a
			fun pastime for a couple of years now. Currently, I'm learning{' '}
			<span className="highlighted">React</span> and{' '}
			<span className="highlighted">Java</span>
		</p>
		<p>
			Aside from that, I have basic knowledge of{' '}
			<span className="highlighted">C#</span>,{' '}
			<span className="highlighted">VueJS</span> and{' '}
			<span className="highlighted">shell scripting.</span>
		</p>
		<p>
			I also like to touch to any types of{' '}
			<span className="highlighted">hardware</span>! Would love to make it my full time work :)
		</p>
	</>
)

export {
	github_username,
	shown_name,
	discord_usertag,
	discord_userid,
	email,
	projects,
	links,
	info,
	fetchGithubRepos, // Export the fetchGithubRepos function
	specificRepos
}